module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-BY04TH4L7W"],"pluginConfig":{"head":true,"respectDNT":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"2148386592033545"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://slatepages.com","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Slate Pages","short_name":"Slate Pages","start_url":"/","background_color":"#f7f0eb","theme_color":"#f15a29","display":"standalone","icons":[{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/favicons/apple-touch-icon-57x57.png","sizes":"57x57","type":"image/png"},{"src":"/favicons/apple-touch-icon-60x60.png","sizes":"60x60","type":"image/png"},{"src":"/favicons/apple-touch-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/apple-touch-icon-76x76.png","sizes":"76x76","type":"image/png"},{"src":"/favicons/apple-touch-icon-114x114.png","sizes":"114x114","type":"image/png"},{"src":"/favicons/apple-touch-icon-120x120.png","sizes":"120x120","type":"image/png"},{"src":"/favicons/apple-touch-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/apple-touch-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/favicons/apple-touch-icon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"/favicons/safari-pinned-tab.svg","rel":"mask-icon","color":"#f15a29"},{"src":"/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"}],"include_favicon":false,"legacy":true,"cache_busting_mode":"query","theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Roboto"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
